import React from 'react';
import { graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DasUniversaleHausderGerechtigkeit = ({ data }) => (
    <InnerPage
        title="Das Universale Haus der Gerechtigkeit (seit 1963)"
        description=""
    >
        <IntroHolder
            title="Das Universale Haus der Gerechtigkeit (seit 1963)"
            subtitle="Das Universale Haus der Gerechtigkeit – weltweites Führungsgremium der Bahá’í-Gemeinde"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Bahá’u’lláh und Sein Anspruch',
                'current-item':
                    'Das Universale Haus der Gerechtigkeit (seit 1963)',
            }}
        >
            <IntroQuote>
                Insofern es für jeden Tag ein neues Problem und für jedes
                Problem eine zweckmäßige Lösung gibt, sind solche
                Angelegenheiten den Geschäftsträgern des Hauses der
                Gerechtigkeit vorzulegen, damit sie nach den Nöten und
                Erfordernissen der Zeit handeln.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Das Universale Haus der Gerechtigkeit ist das weltweit
                    gewählte Führungsgremium der Bahá’í-Gemeinde. Es ist eine
                    Körperschaft, die aus neun Mitgliedern besteht und seit 1963
                    alle fünf Jahre von den Mitgliedern aller Nationalen
                    Geistigen Räte gewählt wird.
                </p>
                <div>
                    <p>
                        Bahá’u’lláh richtete diese Institution bereits in Seinem
                        Heiligsten Buch, dem Kitáb-i-Aqdas, ein Er stattete sie
                        mit der Befugnis aus, in allen Angelegenheiten, die
                        nicht ausdrücklich in den Schriften geregelt sind, für
                        die Gemeindemitglieder verbindliche Gesetze zu erlassen,
                        diese wieder abzuändern oder aufzuheben. Dabei wird das
                        Universale Haus der Gerechtigkeit laut Bahá’u’lláh in
                        seinen Beratungen göttlich geführt, um das Wohlergehen
                        der Menschheit, Bildung, Frieden und globalen Wohlstand
                        zu fördern.
                    </p>
                    <p>
                        Keines der Mitglieder des Universalen Hauses der
                        Gerechtigkeit kann als Einzelner einen besonderen Status
                        oder Autorität für sich beanspruchen. Die Autorität
                        liegt allein bei der Institution und ihrer
                        gemeinschaftlichen Entscheidungsfindung.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Das Universale Haus der Gerechtigkeit lenkt das Wachstum
                        und die Entwicklung der weltweiten Bahá’í-Gemeinde durch
                        eine Reihe von Plänen, welche die Ziele,
                        Herangehensweisen und Methoden für einen systematischen
                        Fortschritt beinhalten. In diese Pläne fließen die
                        Lernerfahrungen der örtlichen und nationalen Gemeinden
                        ein, denen durch die Bahá’í-Gemeindeordnung ein
                        kontinuierlicher Austausch mit dem Universalen Haus der
                        Gerechtigkeit ermöglicht ist.
                    </p>
                    <p>
                        Das Universale Haus der Gerechtigkeit wendet sich
                        regelmäßig mit Botschaften an die Bahá’í der Welt oder
                        an alle Menschen. In seiner Friedensbotschaft von 2019
                        betont es beispielsweise, dass die Menschheit sich als
                        Einheit betrachten muss, um Konflikte zu überwinden.
                        2017 machte es auf die Bedrohung durch den Klimawandel
                        aufmerksam und rief die Menschheit auf, nachhaltig mit
                        begrenzten Ressourcen umzugehen und ihren Blick
                        verstärkt auf das Wohl der ganzen Menschheit zu richten.
                    </p>
                </div>
                <Reference>
                    <p>1. Bahá'u'lláh, Botschaften aus ʻAkká 3:25</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DasUniversaleHausderGerechtigkeit;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "1666981-master.jpg" }) {
            ...fluidImage
        }
        collage: file(
            relativePath: { eq: "haus-der-gerechtigkeit-collage.png" }
        ) {
            ...fluidImage
        }
    }
`;
